import React from "react";
import {
  List, Datagrid, TextField, SimpleForm, TextInput, Edit,
  DateField, NumberField, NumberInput, SelectInput,
  ReferenceField, SelectField, Button,
  useRefresh,
} from "react-admin";
import { CHESS_SERVER_URL, CHESS_SERVER_ADMIN_TOKEN } from "./config";
import Axios from "axios";

export const endStateChoices = [
  { id: null, name: "Not started" },
  { id: "in_progress", name: "In progress" },
  { id: "checkmate", name: "Checkmate" },
  { id: "stalemate", name: "Stalemate" },
  { id: "autodraw", name: "Autodraw" },
  { id: "variant_end", name: "Variant end" },
  { id: "timeout", name: "Timeout" },
  { id: "resigned", name: "Resigned" },
  { id: "draw", name: "Draw" },
];

export const variantChoices = [
  { id: "standard", name: "Standard" },
  { id: "chess960", name: "Chess 960" },
];

export const winnerChoices = [
  { id: "", name: "None" },
  { id: "black", name: "Black" },
  { id: "white", name: "White" },
];

export const GameEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <SelectInput source="variant" choices={variantChoices} />
      <TextInput source="start_fen" />
      <NumberInput source="time" />
      <NumberInput source="increment" />
      <ReferenceField source="white" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField source="black" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <TextInput multiline source="pgn" />
      <TextInput source="end_fen" />
      <SelectInput source="end_state" choices={endStateChoices} />
      <SelectInput source="winner" choices={winnerChoices} />
      <DateField source="start" />
      <DateField source="end" />
      <DateField source="created" />
      <TextField multiline source="best_moves" />
    </SimpleForm>
  </Edit>
);


const EndGameButton = ({ record }) => {
  const refresh = useRefresh();
  
  if(record.end_state != "in_progress") {
    return null;
  }

  // TODO: If this feature is required in production too, move it to the KSQ backend and call it from here
  const endGame = async (gameId) => {
    const result = await Axios.get(
      `${CHESS_SERVER_URL}/games/end/${gameId}`,{
        headers: {
            "Authorization": "Bearer " + CHESS_SERVER_ADMIN_TOKEN
        }
      }
    );

    refresh();
  };

  return (
    <Button
      to={null}
      label="End Game"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        endGame(record.id);
      }}
    ></Button>
  );
};
export const GameList = props => (
  <List {...props} exporter={false} sort={{ field: "id", order: "DESC" }} >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <SelectField source="variant" choices={variantChoices} />
      <NumberField source="time" />
      <NumberField source="increment" />
      <ReferenceField source="white" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField source="black" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <SelectField source="end_state" choices={endStateChoices} />
      <SelectField source="winner" choices={winnerChoices} />
      <DateField source="start" />
      <DateField source="end" />
      <DateField source="created" />
      <EndGameButton/>
    </Datagrid>
  </List>
);