import { ADMIN_URL } from "./config";

export async function api(url, options = {}) {
    if (!options.headers) options.headers = {};
    options.headers["Authorization"] =
        "Bearer " + localStorage.getItem("token");

    if (options.body) {
        options.headers["Content-Type"] = "application/json";
        options.method = "POST";
        options.body = JSON.stringify(options.body);
    }

    if (options.csv) {
        options.headers["Content-Type"] = "text/csv";
    }

    const resp = await fetch(ADMIN_URL + url, options);

    let data;
    if (options.csv) {
        data = await resp.text();
    } else {
        data = await resp.json();
    }

    if (data.error) {
        if (data.error === "Unauthorized") {
            localStorage.removeItem("token");
        }
    }
    return data;
}
