import { stringify } from "query-string";
import { ADMIN_URL } from "./config";

const fetchJson = async (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: "application/json" });
	}
	options.headers.set("Authorization", "Bearer " + localStorage.getItem("token"));
	const resp = await fetch(url, options);
	const json = await resp.json();

	if (resp.status >= 400) {
		throw resp;
	}
	return { ...resp, json };
};

const formatBody = (data) => {
	let multi = false;
	for (const k in data) {
		const v = data[k];
		if (v && typeof v === "object" && v.rawFile instanceof File) {
			multi = true;
			data[k] = v.rawFile;
		}
	}
	if (multi) {
		const formData = new FormData();
		for (const k in data) {
			if (data[k] !== undefined && data[k] !== null) {
				formData.append(k, data[k]);
			}
		}
		return {
			body: formData,
		};
	} else {
		return {
			body: JSON.stringify(data),
			headers: new Headers({ "Content-Type": "application/json" }),
		};
	}
};

export default {
	getList: (resource, params) => {
		let query;
		if (resource === "subscriptions") {
			query = { platform: params.filter.platform };
		} else {
			query = {
				...params.pagination,
				...params.sort,
				...params.filter,
			};
		}
		const url = `${ADMIN_URL}/${resource}?${stringify(query)}`;

		return fetchJson(url).then(({ json }) => {
			return {
				data: json.data,
				total: json.total ? json.total : json.count || json.data.length,
			};
		});
	},

	getOne: (resource, params) => {
		if (resource === "subscriptions") {
			return fetchJson(`${ADMIN_URL}/${resource}/${params.id}`).then(({ json }) => {
				return {
					data: json.data,
				};
			});
		} else {
			return fetchJson(`${ADMIN_URL}/${resource}/${params.id}`).then(({ json }) => {
				// workaround cause react admin expects array of ids
				if (resource === "puzzles") {
					json.data.tags_ids = json.data.tags.map((t) => t.id);
				}
				return {
					data: json.data,
				};
			});
		}
	},

	getMany: (resource, params) => {
		const url = `${ADMIN_URL}/${resource}?ids=${params.ids.join(",")}`;

		return fetchJson(url).then(({ json }) => ({ data: json.data }));
	},

	getManyReference: (resource, params) => {
		const query = {
			target: params.target,
			id: params.id,
			...params.pagination,
			...params.sort,
			...params.filter,
		};
		const url = `${ADMIN_URL}/${resource}?${stringify(query)}`;
		return fetchJson(url).then(({ json }) => ({
			data: json.data,
			total: json.total,
		}));
	},

	update: (resource, params) => {
		// return back tags_ids to array of objects
		if (resource === "puzzles") {
			params.data.tags = params.data.tags_ids.map((id) => ({ id }));
			delete params.data.tags_ids;
		}
		return fetchJson(`${ADMIN_URL}/${resource}/${params.id}`, {
			method: "POST",
			...formatBody(params.data),
		}).then(({ json }) => {
			if (resource === "puzzles") {
				json.data.tags_ids = json.data.tags.map((t) => t.id);
			}
			return { data: json };
		});
	},

	create: (resource, params) =>
		fetchJson(`${ADMIN_URL}/${resource}`, {
			method: "POST",
			...formatBody(params.data),
		}).then(({ json }) => ({
			data: { ...params.data, ...json.data },
		})),

	delete: (resource, params) =>
		fetchJson(`${ADMIN_URL}/${resource}/${params.id}`, {
			method: "DELETE",
		}).then(({ json }) => ({ data: json })),

	deleteMany: (resource, params) => {
		return fetchJson(`${ADMIN_URL}/${resource}?ids=${params.ids.join(",")}`, {
			method: "DELETE",
		}).then(({ json }) => ({ data: json }));
	},
};
