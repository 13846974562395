import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  DateField,
  ReferenceField,
  UrlField,
  DateInput,
  DateTimeInput,
  NumberField,
  NumberInput,
  SelectField,
  SelectInput,
  ReferenceInput,
  BooleanInput,
  required,
} from "react-admin";
import { variantChoices } from "./games";

export const winnerChoices = [
  { id: "", name: "None" },
  { id: "black", name: "Black" },
  { id: "white", name: "White" },
];

export const endStateChoices = [
  { id: "not_started", name: "Not started" },
  { id: "in_progress", name: "In progress" },
  { id: "checkmate", name: "Checkmate" },
  { id: "stalemate", name: "Stalemate" },
  { id: "autodraw", name: "Autodraw" },
  { id: "variant_end", name: "Variant end" },
  { id: "timeout", name: "Timeout" },
  { id: "resigned", name: "Resigned" },
  { id: "draw", name: "Draw" },
];

export const OtbTournamentList = props => (
  <List {...props} exporter={false} sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="site" />
      <DateField source="start" />
      <DateField source="created" />
    </Datagrid>
  </List>
);

export const OtbTournamentEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="site" />
      <DateInput source="start" />
      <DateField source="created" />
    </SimpleForm>
  </Edit>
);

export const OtbTournamentCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="site" />
      <DateInput source="start" validate={[required()]}/>
    </SimpleForm>
  </Create>
);

export const OtbCategoryList = props => (
  <List {...props} exporter={false} sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="tournament_id" reference="otb_tournaments">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <DateField source="created" />
    </Datagrid>
  </List>
);

export const OtbCategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceField source="tournament_id" reference="otb_tournaments">
        <TextField source="name" />
      </ReferenceField>
      <TextInput source="name" />
      <DateField source="created" />
    </SimpleForm>
  </Edit>
);

export const OtbCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="tournament_id" reference="otb_tournaments">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export const OtbRoundEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceField source="tournament_category_id" reference="otb_categories">
        <TextField source="name" />
      </ReferenceField>
      <TextInput source="name" />
      <TextInput source="url" type="url" />
      <DateField source="start" showTime />
      <DateTimeInput source="ended" />
      <DateField source="created" showTime />
      <BooleanInput source="for_parsing" />
    </SimpleForm>
  </Edit>
);

export const OtbRoundList = props => (
  <List {...props} exporter={false} sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="tournament_category_id" reference="otb_categories">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <UrlField source="url" />
      <DateField source="start" showTime />
      <DateField source="ended" showTime />
      <DateField source="created" showTime />
    </Datagrid>
  </List>
);

export const OtbRoundCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="tournament_category_id" reference="otb_categories">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="url" validate={[required()]} type="url" />
      <DateInput source="start" validate={[required()]} />
    </SimpleForm>
  </Create>
);

export const OtbUserList = props => (
  <List {...props} exporter={false} sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="elo" />
      <DateField source="created" />
    </Datagrid>
  </List>
);

export const OtbUserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <NumberInput source="elo" />
      <DateField source="created" />
    </SimpleForm>
  </Edit>
);

export const OtbUserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]}/>
      <NumberInput source="elo" validate={[required()]}/>
    </SimpleForm>
  </Create>
);

export const OtbGameList = props => (
  <List {...props} exporter={false} sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="round_id" reference="otb_rounds">
        <TextField source="name" />
      </ReferenceField>
      <SelectField source="variant" choices={variantChoices} />
      <NumberField source="time" />
      <NumberField source="increment" />
      <ReferenceField label="White" source="white.id" reference="otb_users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Black" source="black.id" reference="otb_users">
        <TextField source="name" />
      </ReferenceField>
      <SelectField source="end" choices={endStateChoices} />
      <SelectField source="winner" choices={winnerChoices} />
      <DateField source="start" />
      <DateField source="created" />
    </Datagrid>
  </List>
);

export const OtbGameEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceField source="round_id" reference="otb_rounds">
        <TextField source="name" />
      </ReferenceField>
      <SelectInput source="variant" choices={variantChoices} />
      <TextInput source="start_fen" />
      <NumberInput source="time" />
      <NumberInput source="increment" />
      <ReferenceInput label="White" source="white.id" reference="otb_users">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Black" source="black.id" reference="otb_users">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput multiline source="pgn" />
      <SelectInput source="end" choices={endStateChoices} />
      <SelectInput source="winner" choices={winnerChoices} />
      <DateField source="start" />
      <DateField source="created" />
    </SimpleForm>
  </Edit>
);
