import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useFetch from "./useFetch";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const DashboardCell = (props) => {
  const { data, title } = props;
  return (
    <Grid item xs>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          {data !== undefined ? data : "?"}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default () => {
  const classes = useStyles();
  const dashData = useFetch("/dashboard");

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <DashboardCell title="Users past day" data={dashData.users_past_day} />
        <DashboardCell title="Users past month" data={dashData.users_past_month} />
        <DashboardCell title="Users total" data={dashData.users_total} />
      </Grid>
      <Grid container spacing={3}>
        <DashboardCell title="Games past day" data={dashData.games_past_day} />
        <DashboardCell title="Games past month" data={dashData.games_past_month} />
        <DashboardCell title="Games total" data={dashData.games_total} />
      </Grid>
      <h3>Chess servers</h3>
      <Grid container spacing={3}>
        <DashboardCell title="Servers" data={dashData.servers ? dashData.servers.length : "?"} />
        <DashboardCell title="Current games" data={dashData.current_games} />
      </Grid>
      {
        dashData.servers && dashData.servers.map(s =>
          <div>
            <h4>Server {s.id}</h4>
            <Grid container spacing={3}>
              <DashboardCell title="Connections" data={s.conns} />
              <DashboardCell title="Turns/s" data={s.throughput} />
            </Grid>
          </div>
        )
      }
    </div>
  );
};