import React from "react";
import {
  List, Datagrid, TextField, SimpleForm, TextInput, Edit,
  DateField, ReferenceField, SelectField, SelectInput, ReferenceInput,
  FormDataConsumer, required, Filter, NumberInput, NumberField,
  ReferenceArrayInput, SelectArrayInput,
} from "react-admin";
import BoardPreview from "./Board";
import { FRONTEND_URL } from "./config";
import { skillLevelChoices } from "./users";

const levelChoices = [
  { id: "beginner", name: "Beginner" },
  { id: "casual", name: "Casual" },
  { id: "club", name: "Club" },
  { id: "league", name: "League" },
  { id: "tournament", name: "Tournament" },
  { id: "advanced", name: "Advanced" },
  { id: "expert", name: "Expert" },
  { id: "master", name: "Master" },
];

const PuzzleFilter = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput 
        source="tag" 
        reference="puzzle_tags"
        filter={{ 
          parent_id: null
        }}
        sort={{
          field: "ordering",
          order: "ASC"
        }}
        alwaysOn
        emptyText={"All"}
        perPage={200}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      { props.filterValues.tag ? (

        <ReferenceInput 
          source="subtag" 
          reference="puzzle_tags"
          filter={{ 
            parent_id: props.filterValues.tag
          }}
          sort={{
            field: "ordering",
            order: "ASC"
          }}
          alwaysOn
          emptyText={"All"}
          perPage={200}
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
      ) : null}

      <SelectInput 
        source="difficulty" 
        emptyText={"All"}
        choices={skillLevelChoices} 
        alwaysOn 
      />
      {/* <NumberInput label="Ranking from" source="ranking_from" alwaysOn />
      <NumberInput label="Ranking to" source="ranking_to" alwaysOn /> */}
    </Filter>
  );
};

const PuzzleLink = ({ record = {} }) => (
  <a href={`${FRONTEND_URL}/puzzles/${record.id}`}
    onClick={e => e.stopPropagation()}>
    Link
  </a>
);

export const PuzzleList = props => (
  <List {...props} filters={<PuzzleFilter />} exporter={false}
    sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField label='Theme' source="theme.id" reference="puzzle_tags">
        <TextField source="title" />
      </ReferenceField>
      <NumberField source="ranking" />
      <SelectField source="level" choices={levelChoices} />
      <DateField source="created" />
      <PuzzleLink source="link" />
    </Datagrid>
  </List>
);

export const PuzzleEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceInput label='Theme' source="theme.id" reference="puzzle_tags"
        filter={{ is_theme: true }} perPage={50}>

        <SelectInput optionText="title" validate={[required()]} />
      </ReferenceInput>
      <ReferenceArrayInput label='Tags' source="tags_ids" reference="puzzle_tags"
        filter={{ is_theme: false }} perPage={50}>

        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput>
      <NumberInput source="ranking" />
      <SelectField source="level" choices={levelChoices} />
      <TextInput source="fen" />
      <FormDataConsumer>
        {({ formData, record }) => BoardPreview(formData, record, "fen")}
      </FormDataConsumer>
      <TextInput source="last_move" />
      <TextInput source="pv" />
      <DateField source="created" />
    </SimpleForm>
  </Edit>
);