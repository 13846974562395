import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useNotify } from "react-admin";
import useFetch from "./useFetch";
import axios from "axios";
import { ADMIN_URL } from "./config";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";

const SubscriptionsDetails = (props) => {
	const notify = useNotify();
    const [data, setData] = useState(null);
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [price_text, setPriceText] = useState("");
    const [price_text_no_trial, setPriceTextNoTrial] = useState("");
    const fetchedData = useFetch(
        `/subscriptions?platform=${props.match.params.client}`
    );

    useEffect(() => {
        if (props.match.params.id && Array.isArray(fetchedData)) {
			const filteredData = fetchedData?.filter(el => el.id === Number(props.match.params.id));
			if (filteredData.length !== 0) {
				setData(filteredData[0]);
			}
        }
    }, [fetchedData]);

    const submitSubscription = async () => {
        const payload = {
            client: props.match.params.client,
            slug: data.slug,
            data: {
                name: name || data.name,
                price: price || data.price,
                subtitle: subtitle || data.subtitle,
                price_text: price_text || data.price_text,
                price_text_no_trial:
                    price_text_no_trial || data.price_text_no_trial,
            },
        };

        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        try {
            await axios.patch(`${ADMIN_URL}/subscriptions`, payload, headers);
            props.history.goBack();
        } catch (error) {
            console.log(error);
			notify(error.message, "warning");
        }
    };

    return (
        <div className="subscription_details">
            <h2>
                {data?.period} subscription for {props.match.params.client} - {data?.currency}
            </h2>
            <form noValidate autoComplete="off">
                <div className="main_inputs">
                    <div className="input">
                        <TextField
                            id="name"
                            label="Name"
                            helperText={data && data.name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="input">
                        <TextField
                            id="price"
                            label="Price"
                            helperText={data && data.price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    </div>
                    <div className="input">
                        <TextField
                            id="subtitle"
                            label="Subtitle"
                            helperText={data && data.subtitle}
                            onChange={(e) => setSubtitle(e.target.value)}
                        />
                    </div>

                    <div className="input">
                        <TextField
                            id="price-text"
                            label="Price text"
                            helperText={data && data.price_text}
                            onChange={(e) => setPriceText(e.target.value)}
                        />
                    </div>

                    <div className="input">
                        <TextField
                            id="price-text-no-trial"
                            label="Price text no trial"
                            helperText={data && data.price_text_no_trial}
                            onChange={(e) =>
                                setPriceTextNoTrial(e.target.value)
                            }
                        />
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className="save-button"
                        startIcon={<SaveIcon />}
                        onClick={submitSubscription}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default withRouter(SubscriptionsDetails);
