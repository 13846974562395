import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import MatchData from "./MatchData";
import SubscriptionDetails from "./SubscriptionDetails";
import styles from "./PGA/PostGameAnalysis.module.scss";

const PGA = lazy(() => import("./PGA/PostGameAnalysis.js"));

const PostGameAnalysis = () => {
    return (
        <Suspense fallback={<div className={styles.wrapper}></div>}>
            <PGA />
        </Suspense>
    );
};

export default [
    <Route
        exact
        path="/subscriptions/:client/:id"
        component={SubscriptionDetails}
    />,
    <Route exact path="/dashboard" component={Dashboard} />,
    <Route exact path="/match-data" component={MatchData} />,
    <Route
        exact
        path="/pga/:gameId/:reportId"
        component={PostGameAnalysis}
        noLayout
    />,
];
