import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupAddIcon from "@material-ui/icons/GroupAdd";

const prettify = (txt) => {
    const titleCase = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    return titleCase.replace("_", " ");
};

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    return (
        <div>
            <MenuItemLink
                to="/dashboard"
                primaryText="Dashboard"
                leftIcon={<DashboardIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {resources.map((resource) => {
                // hide another report section in sidebar, but fetch the data in reports/no_need_review
                if (resource.name === "reports/no_need_review") {
                    return;
                }

                return (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={
                            (resource.options && resource.options.label) ||
                            prettify(resource.name)
                        }
                        leftIcon={
                            resource.icon ? <resource.icon /> : <DefaultIcon />
                        }
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                );
            })}
            <MenuItemLink
                to="/match-data"
                primaryText="Match data"
                leftIcon={<GroupAddIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
