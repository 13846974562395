import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import { List, Datagrid, TextField, DateField, Button } from "react-admin";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import config from "./config";

// const UserFilter = (props) => (
//     <Filter {...props}>
//         <TextInput label="Search" source="q" alwaysOn />
//     </Filter>
// );
console.log("config", config);
const GoToPgaButton = (props) => {
    // const history = useHistory();
    const adminUrl = window.location.origin;

    // console.log("URL", FRONTEND_URL, ADMIN_URL);

    return (
        <Button
            onClick={
                () =>
                    window.open(
                        `${adminUrl}/#/pga/${props.record.game_id}/${props.record.id}`,
                        "_blank"
                    )
                // history.push(`/pga/${props.record.game_id}/${props.record.id}`)
            }
            label={
                props.record.category_name === "cheating" ||
                props.record.category_name === "automatic_cheating"
                    ? "open analysis"
                    : "open report"
            }
        />
    );
};

const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
        >
            {index === value && children}
        </div>
    );
};

const RenderList = (props) => {
    return (
        <List
            {...props}
            sort={{ field: "id", order: "DESC" }}
            exporter={false}
            title="Previous reports"
            resource="reports/no_need_review"
        >
            <Datagrid>
                <TextField source="id" label="Report ID" />
                <DateField source="created" label="Date" showTime />
                <TextField source="username" label="Player reported" />
                <TextField
                    source="reported_username"
                    label="Player reporting"
                />
                <TextField source="category_name" label="Reason" />
                <TextField source="message" label="User personal note" />
                <TextField source="count_reported" label="Number of reports" />
                <GoToPgaButton />
            </Datagrid>
        </List>
    );
};

export const ReportList = (props) => {
    const [value, setValue] = useState("pending");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar position="static" color="transparent">
                <Tabs
                    aria-label="simple tabs example"
                    indicatorColor="primary"
                    textColor="primary"
                    value={value}
                    onChange={handleChange}
                >
                    <Tab
                        label="Pending review"
                        id={"nav-tabpanel-1"}
                        value="pending"
                    />
                    <Tab
                        label="Previous reports"
                        id={"nav-tabpanel-2"}
                        value="previous"
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index="pending">
                <List
                    {...props}
                    sort={{ field: "id", order: "DESC" }}
                    exporter={false}
                    title="Pending review"
                >
                    <Datagrid>
                        <TextField source="id" label="Report ID" />
                        <DateField source="created" label="Date" showTime />
                        <TextField source="username" label="Player reported" />
                        <TextField
                            source="reported_username"
                            label="Player reporting"
                        />
                        <TextField source="category_name" label="Reason" />
                        <TextField
                            source="message"
                            label="User personal note"
                        />
                        <TextField
                            source="count_reported"
                            label="Number of reports"
                        />
                        <GoToPgaButton />
                    </Datagrid>
                </List>
            </TabPanel>
            <TabPanel value={value} index="previous">
                <RenderList {...props} />
            </TabPanel>
        </>
    );
};
