import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {
    List,
    Datagrid,
    TextField,
    Create,
    SimpleForm,
    TextInput,
    Edit,
    DateInput,
    Filter,
    DateField,
    EmailField,
    BooleanInput,
    NumberInput,
    ImageField,
    SelectInput,
    SelectField,
    required,
    email,
    TabbedForm,
    FormTab,
    ReferenceManyField,
    BooleanField,
    ReferenceField,
    NumberField,
    EditButton,
    Button,
    Pagination,
    ImageInput,
    useRefresh,
} from "react-admin";
import { winnerChoices, endStateChoices, variantChoices } from "./games";
import countries from "./countries";
import { api } from "./api";
import Badges from "./components/users/badges";
import DownloadIcon from "@material-ui/icons/GetApp";

const UserTitle = ({ record }) => {
    return <span>{record ? `${record.username}` : ""}</span>;
};

export const skillLevelChoices = [
    { id: "beginner", name: "Beginner" },
    { id: "casual", name: "Casual" },
    { id: "club", name: "Club" },
    { id: "league", name: "League" },
    { id: "tournament", name: "Tournament" },
    { id: "advanced", name: "Advanced" },
    { id: "expert", name: "Expert" },
    { id: "master", name: "Master" },
];

const proLevelChoices = [
    { id: "none", name: "None" },
    { id: "master", name: "Master" },
    { id: "grandmaster", name: "Grandmaster" },
];

const userTierChoices = [
    { id: "free", name: "Free" },
    { id: "premium_level_1", name: "Premium Level 1" },
    { id: "premium_level_2", name: "Premium Level 2" },
    { id: "titled", name: "Titled" },
];

const statusChoices = [
    { id: "active_email_not_verified", name: "Active (email not verified)" },
    { id: "active", name: "Active" },
    { id: "banned", name: "Banned" },
    { id: "deactivated", name: "Deactivated" },
];

const fideTitleChoices = [
    { id: "GM", name: "Grandmaster" },
    { id: "IM", name: "International Master" },
    { id: "FM", name: "FIDE Master" },
    { id: "CM", name: "Candidate Master" },
    { id: "WGM", name: "Woman Grandmaster" },
    { id: "WIM", name: "Woman International Master" },
    { id: "WFM", name: "Woman FIDE Master" },
    { id: "WCM", name: "Woman Candidate Master" },
];

const privacyChoices = [
    { id: "everyone", name: "Everyone" },
    { id: "friends", name: "Friends" },
    { id: "nobody", name: "Nobody" },
];

const notificationChoices = [
    { id: "app", name: "App" },
    { id: "push", name: "Push" },
    { id: "email", name: "Email" },
];

const moveMethodChoices = [
    { id: "drag", name: "Drag" },
    { id: "click", name: "Click" },
    { id: "both", name: "Both" },
];

const countryChoices = countries.map((e) => ({ id: e.code, name: e.name }));

export const boardAndPiecesChoices = [];
for (const i of Array(9).keys()) {
    boardAndPiecesChoices.push({ id: i, name: `${i + 1}.` });
}

const validateUsername = [required()];
const validateEmail = [required(), email()];

const RemoveSubscriptionButton = (props) => {
    const refresh = useRefresh();

    if (props.record && !props.record.subscription_id) {
        return null;
    }

    const onClick = async () => {
        if (
            !window.confirm(
                "Are you sure you wish to remove all subscriptions?"
            )
        )
            return;
        const response = await api(
            "/users/subscriptions/delete/" + props.record.id
        );
        refresh();
    };
    return (
        <Button
            to={null}
            variant={"outlined"}
            color={"primary"}
            label="Remove all subscriptions"
            onClick={(e) => {
                onClick(e);
            }}
        />
    );
};

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="username" validate={validateUsername} />
            <TextInput source="email" type="email" validate={validateEmail} />
            <TextInput source="password" type="password" />
            <TextInput source="google" />
            <TextInput source="facebook" />
            <TextInput source="apple" />
            <TextInput source="firstname" />
            <TextInput source="lastname" />
            <DateInput source="birthdate" />
            <SelectInput source="country" choices={countryChoices} />
            <SelectInput source="skill_level" choices={skillLevelChoices} />
            <SelectInput source="user_tier" choices={userTierChoices} />
            <SelectInput source="pro_category" choices={proLevelChoices} />
            <TextInput source="timezone" />
            <ImageInput
                source="avatar"
                accept="image/*"
                placeholder={<p>Drop image here</p>}
            >
                <ImageField source="src" title="title" />
            </ImageInput>
            <SelectInput source="status" choices={statusChoices} />
            <NumberInput source="ks_rating" />
            <NumberInput source="ks_rating_bullet" />
            <NumberInput source="ks_rating_blitz" />
            <NumberInput source="ks_rating_rapid" />
            <NumberInput source="ks_rating_classic" />
            <NumberInput source="ks_rating_daily" />
            <NumberInput source="ks_rating_chess960" />
            <NumberInput source="fide_number" />
            <SelectInput source="fide_title" choices={fideTitleChoices} />
            <TextInput source="fide_id_scan" />
            <TextInput source="comet_chat_id" />
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => (
    <Edit title={<UserTitle />} {...props}>
        <TabbedForm>
            <FormTab label="profile">
                <TextField source="id" />
                <TextInput source="username" validate={validateUsername} />
                <TextInput
                    source="email"
                    type="email"
                    validate={validateEmail}
                />
                <TextInput source="password" type="password" />
                <TextInput source="google" />
                <TextInput source="facebook" />
                <TextInput source="apple" />
                <TextInput source="firstname" />
                <TextInput source="lastname" />
                <DateInput source="birthdate" />
                <SelectInput source="country" choices={countryChoices} />
                <SelectInput source="skill_level" choices={skillLevelChoices} />
                <SelectInput source="user_tier" choices={userTierChoices} />
                <SelectInput source="pro_category" choices={proLevelChoices} />
                <TextInput source="timezone" />
                <ImageInput
                    source="avatar"
                    accept="image/*"
                    placeholder={<p>Drop image here</p>}
                >
                    <ImageField source="src" title="title" />
                </ImageInput>
                <SelectInput source="status" choices={statusChoices} />
                <NumberInput source="ks_rating" />
                <NumberInput source="ks_rating_bullet" />
                <NumberInput source="ks_rating_blitz" />
                <NumberInput source="ks_rating_rapid" />
                <NumberInput source="ks_rating_classic" />
                <NumberInput source="ks_rating_daily" />
                <NumberInput source="ks_rating_chess960" />
                <TextField source="ks_rating_meta" />
                <NumberInput source="fide_number" />
                <SelectInput source="fide_title" choices={fideTitleChoices} />
                <TextInput source="fide_id_scan" />
                <TextInput source="comet_chat_id" />
                <TextInput source="stripe_customer_id" />
                <DateField source="created" />
                <DateField source="updated" />
            </FormTab>
            <FormTab label="settings">
                <SelectInput source="allow_chat" choices={privacyChoices} />
                <SelectInput
                    source="friends_visibility"
                    choices={privacyChoices}
                />
                <BooleanInput source="incoming_friend_requests" />
                <SelectInput
                    source="incoming_challenge_requests"
                    choices={privacyChoices}
                />
                <BooleanInput source="do_not_disturb" />
                <SelectInput
                    source="correspodence_turn"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="new_challenge"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="correspodence_game_finished"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="otb_tournament_available"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="long_time_no_see"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="friend_request"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="fide_info_moderated"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="card_rejected"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="subscription_confirmed"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="new_learning_content_available"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="important_broadcasting"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="weekly_news_and_adds"
                    choices={notificationChoices}
                />
                <SelectInput
                    source="new_content_theme"
                    choices={notificationChoices}
                />
                <SelectInput source="move_method" choices={moveMethodChoices} />
                <BooleanInput source="highlight_moves" />
                <BooleanInput source="white_bottom" />
                <SelectInput
                    source="board_and_pieces"
                    choices={boardAndPiecesChoices}
                />
                <BooleanInput source="premoves" />
                <BooleanInput source="always_promote_queen" />
                <BooleanInput source="right_click_draws" />
                <BooleanInput source="pause_daily_games" />
                <NumberInput source="max_daily_games" />
            </FormTab>
            <FormTab label="mails">
                <ReferenceManyField
                    reference="mail_logs"
                    target="user_id"
                    pagination={<Pagination />}
                    perPage={10}
                    addLabel={false}
                >
                    <Datagrid>
                        <TextField source="id" />
                        <TextField source="type" />
                        <BooleanField source="success" />
                        <TextField source="msg_id" />
                        <TextField source="data.link" />
                        <DateField source="created" />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="events">
                <ReferenceManyField
                    reference="events"
                    target="user_id"
                    pagination={<Pagination />}
                    perPage={10}
                    addLabel={false}
                >
                    <Datagrid>
                        <TextField source="id" />
                        <TextField source="name" />
                        <TextField source="data" />
                        <DateField source="created" />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="games">
                <ReferenceManyField
                    reference="games"
                    target="user"
                    pagination={<Pagination />}
                    perPage={10}
                    addLabel={false}
                >
                    <Datagrid>
                        <TextField source="id" />
                        <SelectField
                            source="variant"
                            choices={variantChoices}
                        />
                        <NumberField source="time" />
                        <NumberField source="increment" />
                        <ReferenceField source="white" reference="users">
                            <TextField source="username" />
                        </ReferenceField>
                        <ReferenceField source="black" reference="users">
                            <TextField source="username" />
                        </ReferenceField>
                        <SelectField
                            source="end_state"
                            choices={endStateChoices}
                        />
                        <SelectField source="winner" choices={winnerChoices} />
                        <DateField source="start" />
                        <DateField source="end" />
                        <DateField source="created" />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="subscriptions">
                <RemoveSubscriptionButton {...props} />
                {/* <ReferenceManyField reference="subscriptions" target="user_id"
          pagination={<Pagination />} perPage={10} addLabel={false}>
          <Datagrid>
            <TextField source="id" />
            <TextField source="type" />
            <BooleanField source="success" />
            <TextField source="msg_id" />
            <TextField source="data.link" />
            <DateField source="created" />
          </Datagrid>
        </ReferenceManyField> */}
            </FormTab>
            <FormTab label="badges">
                <Badges {...props} />
                {/* <ReferenceManyField reference="subscriptions" target="user_id"
          pagination={<Pagination />} perPage={10} addLabel={false}>
          <Datagrid>
            <TextField source="id" />
            <TextField source="type" />
            <BooleanField source="success" />
            <TextField source="msg_id" />
            <TextField source="data.link" />
            <DateField source="created" />
          </Datagrid>
        </ReferenceManyField> */}
            </FormTab>
        </TabbedForm>
    </Edit>
);

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const Exists = ({ record = {}, source }) =>
    record[source] ? <CheckIcon /> : <CloseIcon />;

const downloadUsers = async () => {
    try {
        const resp = await api("/users/download", { csv: true });

        const d = new Date();
        var dateStr = d.toDateString();

        var csvData = new Blob([resp], { type: "text/csv;charset=utf-8;" });
        var csvURL = window.URL.createObjectURL(csvData);
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `users-${dateStr}.csv`);
        tempLink.click();
    } catch (error) {
        console.log("error", error);
    }
};

export const UserList = (props) => (
    <>
        <List
            filters={<UserFilter />}
            sort={{ field: "id", order: "DESC" }}
            exporter={false}
            {...props}
        >
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="username" />
                <EmailField source="email" />
                <Exists source="google" />
                <Exists source="facebook" />
                <Exists source="apple" />
                <TextField source="firstname" />
                <TextField source="lastname" />
                <SelectField source="skill_level" choices={skillLevelChoices} />
                <SelectField source="status" choices={statusChoices} />
                <DateField source="created" />
                <DateField source="updated" />
            </Datagrid>
        </List>
        <button onClick={downloadUsers} className="exportUsersButton">
            <DownloadIcon />
            EXPORT
        </button>
    </>
);
