import React from "react";
import {
  List, Datagrid, TextField, BooleanField, DateField, ReferenceField
} from "react-admin";

export const MailLogList = props => (
  <List {...props} exporter={false} sort={{ field: "id", order: "DESC" }}>
    <Datagrid isRowSelectable={() => false}>
      <TextField source="id" />
      <ReferenceField source="user_id" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="type" />
      <BooleanField source="success" />
      <TextField source="msg_id" />
      <TextField source="data.link" />
      <DateField source="created" />
    </Datagrid>
  </List>
);