import React from "react";
import {
  List, Datagrid, TextField, DateField, ReferenceField
} from "react-admin";

export const EventList = props => (
  <List {...props} exporter={false} sort={{ field: "id", order: "DESC" }}>
    <Datagrid isRowSelectable={() => false}>
      <TextField source="id" />
      <ReferenceField source="user_id" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="data" />
      <DateField source="created" />
    </Datagrid>
  </List>
);