import { fetchUtils } from "react-admin";
import { ADMIN_URL } from "./config";

const httpClient = fetchUtils.fetchJson;

export default {
  login: async (data) => {
    try {
      const resp = await httpClient(`${ADMIN_URL}/login`, {
        method: "POST",
        body: JSON.stringify(data),
      });
      localStorage.setItem("token", resp.json.data.token);
    } catch (e) {
      throw Error(e.body.error || e.message);
    }
  },
  logout: async () => {
    localStorage.removeItem("token");
  },
  checkError: (resp) => {
    if (resp.status === 401 || resp.status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("token")
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
};