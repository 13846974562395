import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { List, Datagrid, TextField, FunctionField } from "react-admin";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DownloadIcon from "@material-ui/icons/GetApp";

import { api } from "./api";

const TabPanel = (props) => {
	const { children, value, index } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
		>
			{index === value && children}
		</div>
	);
};

const downloadSubscribers = async () => {
	try {
		const resp = await api("/subscriptions/download", { csv: true });

		const d = new Date();
		const dateStr = d.toDateString();

		const csvData = new Blob([resp], { type: "text/csv;charset=utf-8;" });
		const csvURL = window.URL.createObjectURL(csvData);
		const tempLink = document.createElement("a");
		tempLink.href = csvURL;
		tempLink.setAttribute("download", `subscribers-${dateStr}.csv`);
		tempLink.click();
	} catch (e) {
		console.error(e);
	}
};

export const PaymentsList = (props) => {
	const [value, setValue] = useState("web");
	const history = useHistory();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// if (!loading) {
	//     return (
	//         <div className="spinner_page">
	//             <div className="loader"></div>
	//             <span>Loading please wait...</span>
	//         </div>
	//     );
	// }

	const goToSubscriptionDetails = (id, basePath, record) =>
		history.push(`/subscriptions/${value}/${id}`);

	return (
		<>
			<AppBar position='static' color='transparent'>
				<Tabs
					aria-label='simple tabs example'
					indicatorColor='primary'
					textColor='primary'
					value={value}
					onChange={handleChange}
				>
					<Tab label='web' id={"nav-tabpanel-1"} value='web' />
					<Tab label='ios' id={"nav-tabpanel-2"} value='ios' />
					<Tab label='android' id={"nav-tabpanel-3"} value='android' />
				</Tabs>
			</AppBar>
			<TabPanel value={value} index='web'>
				<List {...props} exporter={false} filter={{ platform: "web" }}>
					<Datagrid rowClick={goToSubscriptionDetails}>
						<TextField source='id' />
						<FunctionField
							label='Period'
							render={(record) => `${record.period} - ${record.currency}`}
						/>
						<TextField source='subtitle' />
					</Datagrid>
				</List>
			</TabPanel>
			<TabPanel value={value} index='ios'>
				<List {...props} exporter={false} filter={{ platform: "ios" }}>
					<Datagrid rowClick={goToSubscriptionDetails}>
						<TextField source='id' />
						<FunctionField
							label='Period'
							render={(record) => `${record.period} - ${record.currency}`}
						/>
						<TextField source='subtitle' />
					</Datagrid>
				</List>
			</TabPanel>
			<TabPanel value={value} index='android'>
				<List {...props} exporter={false} filter={{ platform: "android" }}>
					<Datagrid rowClick={goToSubscriptionDetails}>
						<TextField source='id' />
						<FunctionField
							label='Period'
							render={(record) => `${record.period} - ${record.currency}`}
						/>
						<TextField source='subtitle' />
					</Datagrid>
				</List>
			</TabPanel>
			<button onClick={downloadSubscribers} className='exportUsersButton'>
				<DownloadIcon />
				EXPORT SUBSCRIBERS
			</button>
		</>
	);
};
