import React, { useState, useEffect } from "react";
import { SimpleForm, NumberInput, useNotify } from "react-admin";
import useFetch from "./useFetch";
import { api } from "./api";

export default () => {
  const notify = useNotify();
  const dashData = useFetch("/match_data");
  const [data, setData] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (dashData) {
      setData(dashData);
    }
  }, [dashData]);

  const save = values => {
    setSaving(true);

    api("/match_data", {
      body: {
        timeout: values.timeout,
        min_range: values.min_range,
        max_range: values.max_range,
      }
    }).then(resp => {
      if (resp.error) {
        console.error(resp.error);
        notify("Failed to save");

      } else {
        setData(resp.data);
      }
      setSaving(false);

    }).catch(e => {
      console.error(e);
      notify(e.message);
      setSaving(false);
    });
  };

  return (
    <SimpleForm initialValues={data} save={save} saving={saving}>
      <NumberInput disabled source="successes" />
      <NumberInput disabled source="fails" />
      <NumberInput disabled source="avg_wait_time" format={
        data => data !== undefined ? data / 1000 : null
      } />
      <NumberInput source="timeout" />
      <NumberInput source="min_range" />
      <NumberInput source="max_range" />
    </SimpleForm>
  );
};