import React, { Suspense } from "react";
const Chessboard = React.lazy(() => import("chessboardjsx"));

const BoardPreview = (formData, record, source) => {
  let position;
  if (formData) {
    position = formData[source];
  } else {
    position = record[source];
  }
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Chessboard position={position} draggable={false} />
    </Suspense>
  );
};

export default BoardPreview;
