// let API_URL = "http://localhost:8080";
let API_URL = "https://backend.dev.keysquare.io";
if (process.env.REACT_APP_API_URL) {
	API_URL = process.env.REACT_APP_API_URL;
}
let ADMIN_URL = `${API_URL}/admin`;

let FRONTEND_URL = "http://localhost:3001";
if (process.env.REACT_APP_FRONTEND_URL) {
	FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
}

let CHESS_SERVER_URL = "http://localhost:9090";
if (process.env.REACT_APP_CHESS_SERVER_URL) {
	CHESS_SERVER_URL = process.env.REACT_APP_CHESS_SERVER_URL;
}

let CHESS_SERVER_ADMIN_TOKEN = "Hr3E4sFkSJxw0bJOKumvT1xrDfjDHTHrk46fodAx10g";
if (process.env.REACT_APP_CHESS_SERVER_ADMIN_TOKEN) {
	CHESS_SERVER_ADMIN_TOKEN = process.env.REACT_APP_CHESS_SERVER_ADMIN_TOKEN;
}

module.exports = {
	API_URL,
	ADMIN_URL,
	FRONTEND_URL,
	CHESS_SERVER_URL,
	CHESS_SERVER_ADMIN_TOKEN,
};
