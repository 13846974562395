import React from "react";
import { Admin, Resource } from "react-admin";
import UserIcon from "@material-ui/icons/Group";
import EmailIcon from "@material-ui/icons/Email";
import EventIcon from "@material-ui/icons/Event";
import GamesIcon from "@material-ui/icons/Games";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ExtensionIcon from "@material-ui/icons/Extension";
import PaymentIcon from "@material-ui/icons/MonetizationOn";
import LabelIcon from "@material-ui/icons/Label";
import { UserList, UserEdit, UserCreate } from "./users";
import { MailLogList } from "./MailLogList";
import { EventList } from "./EventList";
import Dashboard from "./Dashboard";
import { GameList, GameEdit } from "./games";
import { PuzzleList, PuzzleEdit } from "./puzzles";
import { PuzzleTagList, PuzzleTagEdit } from "./puzzle_tags";
import { PaymentsEdit, PaymentsList } from "./Payments";
import SubscriptionsDetails from "./SubscriptionDetails";
import {
	OtbTournamentList,
	OtbTournamentEdit,
	OtbCategoryList,
	OtbCategoryEdit,
	OtbRoundList,
	OtbRoundEdit,
	OtbUserList,
	OtbUserEdit,
	OtbGameList,
	OtbGameEdit,
	OtbTournamentCreate,
	OtbCategoryCreate,
	OtbRoundCreate,
	OtbUserCreate,
} from "./otb";
import { ReportList } from "./Report";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import KSLayout from "./KSLayout";
import routes from "./routes";

const App = () => (
	<Admin
		layout={KSLayout}
		customRoutes={routes}
		dashboard={Dashboard}
		authProvider={authProvider}
		dataProvider={dataProvider}
	>
		<Resource
			name='users'
			list={UserList}
			edit={UserEdit}
			create={UserCreate}
			icon={UserIcon}
		/>
		<Resource name='mail_logs' list={MailLogList} icon={EmailIcon} />
		<Resource name='events' list={EventList} icon={EventIcon} />
		<Resource name='games' list={GameList} edit={GameEdit} icon={GamesIcon} />
		<Resource name='puzzles' list={PuzzleList} edit={PuzzleEdit} icon={ExtensionIcon} />
		<Resource name='puzzle_tags' list={PuzzleTagList} edit={PuzzleTagEdit} icon={LabelIcon} />
		<Resource
			name='otb_tournaments'
			options={{ label: "OTB | Tournaments" }}
			icon={DateRangeIcon}
			list={OtbTournamentList}
			edit={OtbTournamentEdit}
			create={OtbTournamentCreate}
		/>
		<Resource
			name='otb_categories'
			options={{ label: "OTB | Categories" }}
			icon={DateRangeIcon}
			list={OtbCategoryList}
			edit={OtbCategoryEdit}
			create={OtbCategoryCreate}
		/>
		<Resource
			name='otb_rounds'
			options={{ label: "OTB | Rounds" }}
			icon={DateRangeIcon}
			list={OtbRoundList}
			edit={OtbRoundEdit}
			create={OtbRoundCreate}
		/>
		<Resource
			name='otb_users'
			options={{ label: "OTB | Users" }}
			icon={UserIcon}
			list={OtbUserList}
			edit={OtbUserEdit}
			create={OtbUserCreate}
		/>
		<Resource
			name='otb_games'
			options={{ label: "OTB | Games" }}
			list={OtbGameList}
			edit={OtbGameEdit}
			icon={GamesIcon}
		/>
		<Resource
			name='reports/need_review'
			options={{ label: "Reports" }}
			list={ReportList}
			icon={GamesIcon}
		/>
		<Resource name='reports/no_need_review' />
		<Resource
			name='subscriptions'
			options={{ label: "Payments" }}
			list={PaymentsList}
			edit={SubscriptionsDetails}
			icon={PaymentIcon}
		/>
	</Admin>
);
export default App;
