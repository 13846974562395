import React from "react";
import {
  List, Datagrid, TextField, SimpleForm, TextInput, Edit,
  DateField, BooleanField, BooleanInput, NullableBooleanInput, Filter,
} from "react-admin";

const PuzzleTagFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <NullableBooleanInput label="Theme?" source="is_theme" displayNull alwaysOn/>
  </Filter>
);

export const PuzzleTagList = props => (
  <List filters={<PuzzleTagFilter />} exporter={false} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="description" />
      <BooleanField source="is_theme" />
      <DateField source="created" />
    </Datagrid>
  </List>
);

export const PuzzleTagEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="title" />
      <TextInput source="description" />
      <BooleanInput source="is_theme" />
      <DateField source="created" />
    </SimpleForm>
  </Edit>
);