import React, { useState, useEffect } from "react";
import { Datagrid, SelectInput, Labeled } from "react-admin";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import { api } from "../../api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const Badges = (props) => {
  const [badges, setBadges] = useState([]);
  const [userBadges, setUserBadges] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState(1);

  useEffect(() => {
    _loadBadges();
    _getUserBadges(props.record.id);
  }, []);

  const _loadBadges = async () => {
    const result = await api("/badges");
    if (result && result.data) {
      setBadges(result.data);
    }
  };

  const _issueBadge = async (userId, badgeSlug) => {
    const result = await api("/badges/issue/" + userId + "/" + badgeSlug);

    if (result) {
      alert("Issued!");
      _getUserBadges(props.record.id);
    }
  };

  const _getUserBadges = async (userId, badgeSlug) => {
    const result = await api("/badges/user/" + userId);

    if (result) {
      setUserBadges(result.data);
    }
  };

  const _removeBadge = async (userBadgeId) => {
    const result = await api("/badges/user/remove/" + userBadgeId);

    if (result) {
      _getUserBadges(props.record.id);
    }
  };
  return (
    <div>
      <Grid container display="flex" justify="center">
        <Grid item>
          <FormControl component="fieldset">
            <Labeled label="Select badge">
              <SelectInput
                value={selectedBadge}
                source="badge"
                choices={badges}
                optionText={(item) => item.name + " (" + item.level + ")"}
                optionValue={"slug"}
                onChange={(e) => setSelectedBadge(e.target.value)}
              />
            </Labeled>
            <Button
              variant="contained"
              color="primary"
              onClick={() => _issueBadge(props.record.id, selectedBadge)}
            >
              Issue
            </Button>
          </FormControl>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <h3>Existing badges</h3>
          <Grid container spacing={2}>
            {!userBadges || userBadges.length === 0 ? (
              <div>- none -</div>
            ) : null}
            {userBadges &&
              userBadges.map((row) => (
                <Grid item>
                  <Card style={{ backgroundColor: "rgba(33, 150, 243, 0.3)" }}>
                    <CardContent style={{ textAlign: "center" }}>
                      <div>
                        <img
                          src={row.badge.image}
                          style={{
                            width: "100%",
                            maxWidth: 130,
                            height: "auto",
                          }}
                        />
                      </div>
                      <div style={{ marginTop: 5 }}>
                        <strong>{row.badge.name}</strong>
                      </div>
                      <div style={{ marginTop: 5 }}>{row.badge.level}</div>
                      <div style={{ marginTop: 5 }}>Issued: {row.issued}</div>
                      <div style={{ marginTop: 5 }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            window.confirm("Are you sure?") &&
                            _removeBadge(row.id)
                          }
                        >
                          Remove
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Badges;
