import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchStart, fetchEnd, useNotify } from "react-admin";
import { api } from "./api";

const useFetch = (url) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [data, setData] = useState({});

  useEffect(() => {
    dispatch(fetchStart());

    api(url)
      .then(data => {
        if (data.error) {
          notify(data.error);
        } else {
          setData(data.data);
        }
        dispatch(fetchEnd());
      })
      .catch((e) => {
        notify(e.message);
        dispatch(fetchEnd());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
};

export default useFetch;